import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as firebase from 'firebase/app';
import 'firebase/storage';

import '../src/css/style.css';

Vue.config.productionTip = false;

import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';


/* Font Awesome setting */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
library.add(faFloppyDisk)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Configura tus credenciales de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCEanRTRbtsz9WZ3cJsKJ68bmZlF0cYEVs",
  authDomain: "edm-sv.firebaseapp.com",
  projectId: "edm-sv",
  storageBucket: "edm-sv.appspot.com",
  appId: "1:608769672654:web:eacd91873df6580c75579f",
};
// Inicializa Firebase
firebase.initializeApp(firebaseConfig);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
