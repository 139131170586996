import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'access',
    component: () => import('../views/AccessView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/Estudiantes',
    name: 'students',
    component: () => import('../views/StudentsView.vue'),
  },
  {
    path: '/Estudiantes/NuevoEstudiante',
    name: 'newStudent',
    component: () => import('../views/NewStudenView.vue')
  },
  {
    path: '/Instructores',
    name: 'instructors',
    component: () => import('../views/Instructor/InstructorView.vue')
  },
  {
    path: '/Instructores/NuevoInstructor',
    name: 'newInstructors',
    component: () => import('../views/Instructor/NewInstructorView.vue')
  },
  {
    path: '/Instructores/EditarInstructor/:id',
    name: 'editInstructors',
    component: () => import('../views/Instructor/EditInstructorView.vue')
  },
  {
    path: '/Horarios',
    name: 'schedules',
    component: () => import('../views/Schedules/SchedulesView.vue')
  },
  {
    path: '/Horario/NuevoHorario',
    name: 'newSchedule',
    component: () => import('../views/Schedules/NewScheduleView.vue')
  },
  {
    path: '/Horario/EditarHorario/:id',
    name: 'editSchedule',
    component: () => import('../views/Schedules/EditScheduleView.vue')
  },
  {
    path: '/Cupos',
    name: 'quotas',
    component: () => import('../views/QuotasView.vue')
  },
  {
    path: '/Instructores/FaltasInstructor/:id',
    name: 'faultsInstructor',
    component: () => import('../views/Instructor/FaultsInstructorView.vue')
  },
  {
    path: '/Instructores/HorasExtra/:id',
    name: 'extraHour',
    component: () => import('../views/Instructor/ExtraHourView.vue')
  },
  {
    path: '/Instructores/QuejasInstructor/:id',
    name: 'complaintsInstructor',
    component: () => import('../views/Instructor/ComplaintsInstructorView.vue')
  },
  {
    path: '/Instructores/ListaAlumnos/:id',
    name: 'listStudent',
    component: () => import('../views/Instructor/ListStudentsView.vue')
  },
  {
    path: '/Instructores/NuevaFalta/:id',
    name: 'newFalts',
    component: () => import('../views/Instructor/NewFaltsView.vue')
  },
  {
    path: '/Instructores/NuevaQueja/:id',
    name: 'newComplain',
    component: () => import('../views/Instructor/NewComplainView.vue')
  },
  {
    path: '/Diploma/:id',
    name: 'saveDiploma',
    component: () => import('../views/SaveDiplomaView.vue')
  },
  {
    path: '/FichaRegistro/:id',
    name: 'registerSheet',
    component: () => import('../views/RegisterSheetView.vue')
  },
  {
    path: '/Permisos/:id',
    name: 'permitis',
    component: () => import('../views/Instructor/PermitsView.vue')
  },
  {
    path: '/Permisos/Nuevo/:id',
    name: 'newPermitis',
    component: () => import('../views/Instructor/NewPermitsView.vue')
  },
  {
    path: '/Estudiantes/Editar/:id',
    name: 'editStudent',
    component: () => import('../views/EditStudenView.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
